import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';
import { intlShape, injectIntl } from 'react-intl';
import Redirect from '../../components/Redirect';

import withAnalytics, { withAnalyticsPropType } from '../../hocs/withAnalytics';
import withAuth, { withAuthPropType } from '../../hocs/withAuth';
import withLocaleProvider from '../../hocs/withLocaleProvider';

const ANALYTICS_PAGE_NAME = 'Homepage';

/**
 * Home Page
 */
class Home extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    analytics: withAnalyticsPropType.isRequired,
    auth: withAuthPropType.isRequired
  };

  componentDidMount() {
    const { analytics } = this.props;

    analytics.trackRoute(ANALYTICS_PAGE_NAME);
  }

  render() {
    return <Redirect route="Dashboard" method="replace" />;
  }
}

export default compose(
  withLocaleProvider,
  injectIntl,
  withAuth(),
  withAnalytics()
)(Home);
