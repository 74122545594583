import React, { Component } from 'react';

import { localePropType, LocaleProvider } from '../../locales';

/**
 * Export a shape for use with this HOC.
 */
export const withLocaleProviderPropType = localePropType;

/**
 * This higher order component is intended to be wrapped around `page` components
 * and is needed to set the locale context.
 *
 * This only exists to work around a bug with Next that does not allow for context
 * to be set in the `_app` component:
 * https://github.com/zeit/next.js/issues/4194
 *
 * @param {Component} WrappedComponent React component to wrap.
 */
export default function withLocaleProvider(WrappedComponent) {
  class WithLocaleProvider extends Component {
    static displayName = `withLocaleProvider(${WrappedComponent.displayName || WrappedComponent.name})`;

    // Must copy over the getInitialProps static method
    static getInitialProps = WrappedComponent.getInitialProps;

    static propTypes = {
      locale: localePropType.isRequired
    };

    render() {
      const { locale } = this.props;

      return (
        <LocaleProvider value={locale}>
          <WrappedComponent {...this.props} />
        </LocaleProvider>
      );
    }
  }

  return WithLocaleProvider;
}
